<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th width="3%" class="mr-2 mt-1" v-if="editable">
                    <Check 
                        v-model="masterCheck" 
                        @change="checked => $emit('handlerMasterSelect', checked)"
                    />
                </th>
                <th><Ellipsis>Tipo</Ellipsis></th>
                <th><Ellipsis>Cód. TUSS</Ellipsis></th>
                <th><Ellipsis>Nome</Ellipsis></th>
                <th><Ellipsis>{{getValueLabel()}}</Ellipsis></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(procedure, index) in procedures" :key="index">
                <td v-if="editable">
                    <Check 
                        :value="selecteds.includes(procedure.id)" 
                        @input="checked => !checked && masterCheck ? (masterCheck = false) : null"
                        @change="$emit('handlerSelect', procedure)" 
                    />
                </td>
                <td>{{ parseTypeToLabel(procedure.item?.type) }}</td>
                <td>{{ procedure.item?.code ?? '-' }}</td>
                <td><Ellipsis>{{ procedure.item?.name }}</Ellipsis></td>
                <td>{{ getValue(procedure) }}</td>
                <td>
                    <div class="actions">
                        <Edit  
                            class="icon" 
                            v-b-tooltip.hover.rightbottom title="Editar item"
                            @click="() => $emit('edit', procedure)"
                        />
                        <Delete 
                            v-if="editable"
                            class="icon" 
                            v-b-tooltip.hover title="Excluir item"
                            @click="() => deleteItem(procedure)"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper';
import { parseTypeToLabel } from '@items/utils/constants';
import * as TABLE from '@tables/utils/constants';

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),   
        Check: () => import('@/components/General/Check'),
        Delete: () => import('@/assets/icons/delete.svg'),
        Edit: () => import('@/assets/icons/edit.svg'),
    },
    props: {
        editable: Boolean,
        procedures: {
            type: Array,
            default: []
        },
        selecteds: {
            type: Array,
            default: []
        },
        tableType: {
            type: String,
            default: ''
        }
    },
    computed: {
        itemValue() {
            return this.tableType === TABLE.TABLE_PROCEDURE_TYPE_PRIVATE ? TABLE.TABLE_PROCEDURE_TYPE_PRIVATE : TABLE.TABLE_PROCEDURE_TYPE_HEALTH_PLAN
        }
    },
    data() {
        return {
            masterCheck: false
        }
    },
    methods: {
        parseTypeToLabel,
        parseNumberToMoney,
        calculateValue(procedure) {
            if (procedure.value) return parseNumberToMoney(procedure.value)
            else return parseNumberToMoney(procedure.medical_fee + procedure.anesthetic_port + procedure.m2_filme + procedure.operational_cost)
        },
        deleteItem(procedure) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir o <span>Item ${procedure.item.name}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', procedure)
                }
            })
        },
        getValue(procedure) {
            return this.tableType === TABLE.TABLE_PROCEDURE_TYPE_PRIVATE ? parseNumberToMoney(procedure.value) : parseNumberToMoney(procedure.medical_fee)
        },
        getValueLabel() {
            return this.tableType === TABLE.TABLE_PROCEDURE_TYPE_PRIVATE ? 'Valor' : 'Honorário Médico'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

</style>